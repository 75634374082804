@import "./assets/fonts/index.css";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-family: "Gilroy-Regular";
  line-height: 1;
  overflow: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  height: 100%;

  .App {
    height: 100%;
  }

  --color-theme-primary: #3b4799;
  --color-theme-secondary: #767eb7;
  --color-theme-primary-background: #3b479904;
}

.main-title-page {
  font-size: 36px;
  font-family: "Gilroy-ExtraBold";
  color: #000000;
  padding-left: 50px;
}

.main-page-wrapper {
  display: flex;
  .main-page-box {
    position: relative;
    width: calc(100% - 100px);
    overflow-y: scroll;
    height: 100vh;
    background: #fafbfd;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .main-tabs-box {
    width: 100%;
    padding: 25px 50px;
    background: #fafbfd;
  }
}

.main-box {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(4, 35, 70, 0.06), 0 2px 4px 0 rgba(72, 112, 246, 0.1);
  background-color: #ffffff;
  padding: 20px 20px;
}
.main-box-no-padding {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(4, 35, 70, 0.06), 0 2px 4px 0 rgba(72, 112, 246, 0.1);
  background-color: #ffffff;
}

.chart-wrapper-box {
  display: flex;
  justify-content: space-between;
  // overflow: hidden;
}

.chart-price-wrapper {
  position: relative;
}

.chartContainer {
  overflow: hidden !important;
}

.enableOverflow {
  overflow: auto !important;
}

.initialOverflow {
  overflow: initial !important;
}

.chart-title-desc {
  font-family: "Gilroy-Medium";
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  max-width: 522px;
  align-items: center;
  display: flex;
  .percent {
    margin: 0 5px;
  }
  .bold-text {
    font-family: "Gilroy-ExtraBold";
    margin-left: 4px;
  }
}

.chart-desc {
  max-width: 525px;
}

.chart-title-desc-rating,
.chart-title-desc-promo {
  font-family: "Gilroy-Medium";
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  .percent {
    margin: 0 5px;
  }
  .bold-text {
    font-family: "Gilroy-ExtraBold";
    margin-left: 4px;
  }
}

//tabs styles
.ant-tabs-nav {
  margin-bottom: 25px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  background: var(--color-theme-primary);
  height: 4px;
  border-radius: 4px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 4px;
  height: 44px;
  border-radius: 100px;
  background-color: var(--color-theme-primary);
}

.ant-tabs {
  overflow: unset;
}

.ant-tabs-tab {
  margin: 0 36px 0 0;
}

.ant-tabs-nav-wrap {
  .ant-tabs-tab-btn {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #abaaba;
    line-height: 1.11;
    font-family: "Gilroy-ExtraBold";
  }
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #000;
    font-weight: bold;
  }
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-left > .ant-tabs-nav::before {
  border-bottom: none;
}
//end

.filters-box {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 22px;
  right: 25px;
}

.ant-select-item-option-state {
  display: none;
}
.ant-select-multiple .ant-select-selection-item {
  margin-right: 0px;
}
.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0px;
}
.wrapper-category-availability {
  white-space: pre-wrap;
  width: 250px;
  font-family: "Gilroy-Medium";
  position: relative;
  z-index: 999;
  padding: 8px;
  border-radius: 2px;
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  background: #ffffff;
  font-size: 12px;
  .title {
    border: none !important;
    margin-bottom: 10px !important;
  }
  .wrapper-box-availability {
    display: flex;
    // justify-content: space-between;
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 44px;
      justify-content: space-between;
      .availability {
        display: flex;
        align-items: center;
        .color {
          width: 11px;
          height: 11px;
          margin-right: 5px;
        }
      }
    }
    .screenshot-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        width: 44px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eef2f7;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.highcharts-tooltip {
  & > span {
    .wrapper-category-availability {
      white-space: pre-wrap;
      width: 250px;
      font-family: "Gilroy-Medium";
      position: relative;
      z-index: 999;
      padding: 8px;
      border-radius: 2px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      background: #ffffff;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      .title {
        border: none !important;
        margin-bottom: 10px !important;
        font-family: "Gilroy-ExtraBold";
        font-weight: 800;
      }
      .wrapper-box-availability {
        display: flex;
        justify-content: space-between;
        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // height: 44px;
          // justify-content: space-between;
          .date {
            margin-bottom: 5px;
          }
          .availability {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .color {
              width: 11px;
              height: 11px;
              margin-right: 5px;
            }
          }
          .show-details {
            color: var(--color-theme-primary);
            font-weight: bold;
          }
        }
        .screenshot-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            width: 44px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #eef2f7;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
    .wrapper-total {
      font-family: "Gilroy-Medium";
      padding: 10px;
      position: relative;
      z-index: 999;
      border-radius: 2px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      background: #ffffff;
      .title {
        font-size: 12px;
        font-weight: 800;
        color: #000000;
        margin-bottom: 10px;
      }
      .wrapper,
      .retailer-price {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .box div {
          color: #000000;
          font-size: 12px;
        }
        .total-data {
          margin-bottom: 10px;
        }
        .line {
          width: 2px;
          margin: 0 10px;
          background: #dbdbdd;
        }
      }
      .retailer-title {
        text-transform: capitalize;
        font-weight: 800;
        margin-bottom: 10px;
      }
    }
    .wrapper-category-discount-cut {
      min-width: 200px;
      width: 100%;
    }
    .wrapper-category-discount-cut,
    .wrapper-category {
      font-family: "Gilroy-Medium";
      position: relative;
      z-index: 999;
      padding: 8px;
      border-radius: 2px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      background: #ffffff;
      .total-data {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        color: #000000;
      }
      .wrapper-box {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .wrapper-box-promotion {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
      .wrapper-box-no-margin {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      .lowest {
        color: #ff6866;
        font-size: 10px;
        font-family: "Gilroy-ExtraBold";
        margin-top: 2px;
        display: block;
      }
      .best {
        color: #1ec68d;
        font-size: 10px;
        font-family: "Gilroy-ExtraBold";
        margin-top: 2px;
        display: block;
      }
      .title {
        font-size: 12px;
        font-weight: 800;
        color: #000000;
        text-transform: capitalize;
      }
      .tip {
        font-size: 12px;
        font-weight: 800;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .date {
        font-size: 12px;
        color: #000000;
        margin: 5px 0;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 4px 0;
        .box {
          display: flex;
          align-items: center;
          margin-right: 25px;
          .color {
            width: 14px;
            height: 14px;
            border-radius: 10%;
          }
          .name {
            margin-left: 5px;
            text-transform: capitalize;
          }
          img {
            height: 20px;
            margin-left: 5px;
          }
        }
      }
      .price-compare {
        margin: 5px 0;
      }
      .line {
        width: 2px;
        margin: 0 10px;
        background: #dbdbdd;
      }
    }
  }
}

.highcharts-legend-item-hidden {
  span {
    .label-box {
      display: inline-flex;
      padding: 5px 5px 4px 4px;
      border: solid 2px #dbdbdd;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      margin: 2px;

      .color {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        margin-right: 5px;
      }
      .name {
        font-size: 10px;

        color: #5d6a7d;
      }
    }
    .label-box:hover {
      border: solid 2px var(--color-theme-primary);
      .name {
        font-size: 10px;

        color: var(--color-theme-primary);
      }
    }
  }
}

.label-box {
  display: inline-flex;
  padding: 5px 5px 4px 4px;
  border: solid 1px var(--color-theme-primary);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 2px 4px;
  min-height: 28px;
  &:hover {
    cursor: pointer;
  }

  .color {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 5px;
  }
  .name {
    font-size: 10px;

    color: #5d6a7d;
    text-transform: capitalize;
  }
}

.about-icon {
  position: absolute;
  top: -5px;
  width: 29px;
  height: 28px;
  object-fit: contain;
}

.line-separete {
  width: 2px;
  background: #dbdbdd;
  display: block;
  margin: 0 10px;
}

.price-range-wrapper {
  width: 100%;
  display: flex;
  overflow-x: auto;
  margin-top: 25px;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .priceRange-chart {
    margin-right: 25px;
  }
}
.ant-tooltip {
  z-index: 9999;
}
.ant-tooltip-inner {
  font-size: 15px;
  font-weight: 500;
  min-width: 30px;
  min-height: 32px;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  word-wrap: break-word;
  background-image: radial-gradient(circle at 108% -28%, #6373e1, var(--color-theme-primary) 9 62%);
  border-radius: 2px;
  -webkit-box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.2);
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.2);
}

.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: #3c4799;
  content: "";
  pointer-events: auto;
}

span.ant-radio + * {
  font-family: "Gilroy-Medium";
}

.highcharts-credits {
  display: none;
}

.pie-chart-promotions {
  width: 444px;
  margin-top: 40px;
  position: relative;
}

.shrink-btn {
  min-width: 150px;
  background: #eef2f7;
  border: none;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  font-family: "Gilroy-Medium";
}

.brand-labels-row {
  display: flex;
  flex-wrap: wrap;
  // margin-left: 40px;
  position: relative;
  .brand-name-wrapper {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border: 1px solid #5d6a7d;
    margin: 2px 2px;
    border-radius: 4px;
    & > :hover {
      cursor: pointer;
      background-color: "#eef2f7";
    }
    .brand-name-color-box {
      width: 8px;
      height: 8px;
      margin-right: 5px;
    }
    .brand-name {
      font-size: 10px;
      color: #5d6a7d;
      text-transform: capitalize;
    }
  }
}
.display-null {
  display: none;
}

.too-more-data-movers,
.too-more-data-pie,
.too-more-data {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  height: 400px;
  background-color: rgba(4, 35, 70, 0.06);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > p {
    font-size: 16px;
    color: #5d6a7d;
  }
}

.too-more-data {
  top: 62px;
}
.too-more-data-pie {
  top: 22px;
}
.too-more-data-movers {
  top: 0;
}

.too-more-data-ta {
  top: 40px;
  left: 0;
  right: 0;
  height: 300px;
  background-color: rgba(4, 35, 70, 0.06);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > p {
    font-size: 16px;
    color: #5d6a7d;
  }
}

.more-brands-modal {
  // position: absolute;
  overflow-y: scroll;
  // top: 40px;
  // left: 0;
  height: auto;
  max-height: 300px;
  width: 560px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  border-radius: 2px;
  z-index: 999;
}

.capitalize {
  text-transform: capitalize;
}
.ant-pagination-total-text {
  position: absolute;
  left: 0;
  font-size: 14px;
}
.pagination-with-per-page {
  position: relative;
}

.pagination-controls {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin-top: 40px;
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: var(--color-theme-primary);
    border-radius: 4px;
    border: none;
    font-size: 16px;

    span svg path {
      fill: #fff;
    }
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item a {
    font-size: 14px;
    font-weight: 500;
    color: #9696a0;
  }
  .ant-pagination-item-active {
    border: none;
  }
  .ant-pagination-item-active a {
    color: #000000;
    font-size: 14px;
    font-weight: 800;
    border-bottom: 4px solid var(--color-theme-primary);
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--color-theme-primary);
  }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected,
.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: none !important;
  font-weight: normal !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: none !important;
}

.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
  .ant-select-selector {
    background: #d9dfe6;
    border: none;
    box-shadow: none;
  }
}

.ant-radio-inner {
  width: 18px !important;
  height: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: #8a99a8 !important;
  border-width: 3px !important;
}

.ant-radio.ant-radio-checked {
  .ant-radio-inner {
    border-color: var(--color-theme-primary) !important;
    border-width: 3px !important;
    width: 18px !important;
    height: 18px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    &:after {
      width: 8px !important;
      height: 8px !important;
      background-color: var(--color-theme-primary) !important;
      position: absolute !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
    }
  }
}

.ant-select.ant-select-focused.ant-select-multiple.ant-select-open {
  .ant-select-selector {
    background: #d9dfe6;
    border: none;
    box-shadow: none;
  }
  .ant-select-selection-item-content {
    background: #d9dfe6 !important;
    border: none !important;
    .ant-select-selection-item {
      background: #d9dfe6 !important;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-theme-primary) !important;
  border-color: var(--color-theme-primary) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--color-theme-primary) !important;
}

.ant-select.ant-select-focused.ant-select-single.ant-select-show-arrow {
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

.ant-select.ant-select-focused.ant-select-multiple {
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
}

.retailer-img {
  margin-right: 10px;
  width: 20px;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  .ant-radio-wrapper.ant-radio-wrapper-checked {
    & > span {
      display: flex;
      align-items: center;
    }
  }
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
  & > span {
    display: flex;
    align-items: center;
  }
}

.wrapper-box-relative {
  position: relative;
  min-height: 120px;
}
.hightlighted {
  z-index: 999;
}
.not-hightlighted {
  z-index: 1;
  stroke-width: 0;
  opacity: 0.3;
}
.not-hightlighted-media {
  opacity: 0.2;
}
.hightlighted-media {
  opacity: 0.7;
}

.not-hightlighted-label {
  opacity: 0.3 !important;
}

.highcharts-color-0 {
  stroke-width: 0;
}

.round-corners {
  border-top-left-radius: 10px;
  border-top-right-radius: 5px;
}

.current-table-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}
.wrapper-table-box.ranking {
  overflow: auto;
  max-height: 600px;
  &::-webkit-scrollbar {
    width: 5px;
  }
}
.wrapper-table-box {
  width: 100%;
  overflow-x: auto;
  display: inline-block;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
}
.box-wrapper {
  width: 100%;
  display: flex;
}
.title-popup {
  // width: 400px;
  height: 80px;
  font-family: "Gilroy-Medium";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #dfdfea;
  border-left: none;
  border-right: none;
  .image {
    width: 50px;
    height: 50px;
  }
  span {
    width: 310px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000000;
    padding: 0 10px;
    @media (max-width: 1440px) {
      width: 200px;
    }
  }
  .averagePrice {
    width: 360px;
    text-align: center;
    @media (max-width: 1440px) {
      width: 250px;
    }
  }
  .search {
    width: 360px;
    position: relative;
    font-size: 14px;
    color: #000000;
    @media (max-width: 1440px) {
      width: 250px;
    }
    img {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    input {
      border: none;
      background-color: #eef2f7;
      width: 330px;
      height: 44px;
      border-radius: 2px;
      outline: none;
      padding-left: 40px;
      padding-right: 12px;
      @media (max-width: 1440px) {
        width: 220px;
      }
    }
  }
}
.wrapper {
  width: 100%;
  display: flex;
  .no-listed {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .table-item {
    width: 160px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfea;
    border-right: none;
    @media (max-width: 1440px) {
      width: 116px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: "Gilroy-Medium";
        width: 60px;
        text-align: center;
      }
      .box-empty {
        width: 24px;
      }
    }
    .price {
      font-family: "Gilroy-ExtraBold";
      font-size: 14px;
      font-weight: 800;
      color: #374456;
    }
    .notListed {
      color: #abaaba;
      font-size: 10px;
    }
  }
}
.table-item.sort {
  flex-direction: row;
}
.bestPrice {
  background-color: #d8f7ef;
}
.highestPrice {
  background-color: #ffefef;
}

.ranking-modal.ant-modal {
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding: 25px;
  top: 0;
  .ant-modal-content {
    width: auto;
    display: inline-block;
    overflow-y: auto;
  }
  .ant-modal-footer {
    display: none;
  }
  .retailer-logo {
    width: 24px;
    height: 24px;
  }
}

.change-legends-wrapper {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .more {
    display: inline-flex;
    padding: 5px 5px 4px 4px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin: 2px 4px;
    background-color: rgb(59, 71, 153);
    border: 2px solid rgb(59, 71, 153);
    font-size: 11px;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
  }
}
.ant-popover-inner-content {
  //Ruslan comment for you
  //нужно найти компромис по использованию этого стиля
  //ты его переписал и мои поповеры все поплыли
  // padding: 15px 0;
  // box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  padding: 15px 15px;
  .thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 18px;
    border: 1px solid #ececf0;
    border-radius: 2px;
    & img {
      width: 100%;
      max-width: 190px;
    }
    .no-iamge {
      font-family: "Gilroy-Medium";
      font-size: 12px;
      text-align: center;
      color: #9696a0;
      font-weight: bold;
    }
  }
}
.legends-dropdown {
  max-width: 560px;
  width: auto;
  display: inline-flex;
  flex-wrap: wrap;
  right: 0;
  top: 42px;
  background: #fff;
  padding: 0 15px;
  border-radius: 4px;
  z-index: 12;
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
}
.legend-box {
  display: inline-flex;
  padding: 5px 5px 4px 4px;
  border: solid 1px rgb(219, 219, 221);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 2px 4px;
  &:hover {
    cursor: pointer;
  }
  .color {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 5px;
  }
  .name {
    font-size: 11px;

    color: #5d6a7d;
    text-transform: capitalize;
  }
}
.legend-box.active-legend {
  border: solid 2px var(--color-theme-primary);
  & > span {
    font-weight: bold;
    color: rgb(59, 71, 153);
  }
}

.ant-slider-track {
  background-color: #c1c8f6;
}

.ant-slider-handle {
  background-color: var(--color-theme-primary);
  border: none;
  width: 20px;
  height: 20px;
  margin-top: -8px;
}
.ant-slider-handle-1::before {
  font-size: 8px;
  content: "|";
  color: white;
  position: absolute;
  left: 8px;
  font-weight: 900;
  top: 4px;
}
.ant-slider-handle-1::after {
  font-size: 4px;
  content: "|";
  color: white;
  position: absolute;
  left: 11px;
  top: 7px;
  font-weight: 900;
}
.ant-slider-handle-2::before {
  font-size: 8px;
  content: "|";
  color: white;
  position: absolute;
  left: 10px;
  font-weight: 900;
  top: 4px;
}
.ant-slider-handle-2::after {
  font-size: 4px;
  content: "|";
  color: white;
  position: absolute;
  left: 8px;
  top: 7px;
  font-weight: 900;
}

.ant-slider-step {
  display: none;
}

.ant-slider:hover .ant-slider-track {
  background: #adb4dd !important;
}

.calendar-bar-label {
  font-family: Gilroy-Regular;
  font-size: 12px;
  text-transform: capitalize;
}

.highcharts-series {
  margin-top: 20px;
}

.wrapper-chart-box {
  width: 100%;
  position: relative;
}

.chart-no-data {
  position: absolute;
  height: 96%;
  width: 100%;
  background-color: #f0f2f4;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #5d6a7d;
  font-family: "Gilroy-Medium";
}
.chart-no-data.maxheight {
  height: 100%;
}
.chart-no-data.static {
  height: 120px;
  position: static;
}
.chart-no-data.opacity {
  background-color: rgba(240, 242, 244, 0.8);
  text-align: center;
}

.emptyData {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(4, 35, 70, 0.06);
  padding: 16px;
  text-align: center;
  & > p {
    font-size: 16px;
    color: #5d6a7d;
    font-family: "Gilroy-Medium";
  }
}

//ant rangePicker
.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--color-theme-primary) !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--color-theme-primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--color-theme-primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--color-theme-primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--color-theme-primary) !important;
  opacity: 0.2;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: var(--color-theme-primary) !important;
  opacity: 0.2;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: var(--color-theme-primary) !important;
  opacity: 0.5;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: var(--color-theme-primary) !important;
  opacity: 0.5;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background: var(--color-theme-primary) !important;
  opacity: 0.5;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: var(--color-theme-primary) !important;
  opacity: 0.5;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: var(--color-theme-primary) !important;
}

.ant-popover-content {
  width: auto;
}
.ant-popover-title {
  border-bottom: none;
  font-size: 12px;
  font-family: "Gilroy-ExtraBold";
  padding: 10px 16px 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin: 0;
  max-width: 100%;
}
.more-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}

.popup-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
  .rating-popup-rating {
    display: flex;
    align-items: center;
  }
  .rating-popup-trend-bottom {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .popup-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      display: flex;
      align-items: center;
      min-height: 24px;
    }
    .flex-container {
      display: flex;
      align-items: center;
      .popup-color {
        width: 11px;
        height: 11px;
        border-radius: 2px;
        margin-right: 5px;
      }
    }
  }
  & > span {
    margin-top: 16px;
    font-size: 12px;
    color: var(--color-theme-primary);
    font-family: "Gilroy-ExtraBold";
    cursor: pointer;
  }
}
.popup-with-img {
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    max-width: 100%;
    height: 220px;
  }
}

.ant-dropdown-menu {
  max-height: 382px;
  overflow: auto;
  padding: 12px 16px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }

  .products-count {
    padding: 10px 0;
    font-size: 12px;
    color: #9696a0;
    font-family: "Gilroy-ExtraBold";
  }
  .product-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    & > img {
      width: 24px;
      margin-right: 8px;
    }
    & > span {
      font-size: 14px;
      font-family: "Gilroy-Medium";
    }
  }
}
.main-page-header {
  font-size: 36px;
  font-family: "Gilroy-ExtraBold";
  font-weight: bold;
  padding-left: 50px;
  background: #fafbfd;
}

.wrapper-chart {
  cursor: pointer;
}

.printscreen-absolute {
  width: 100%;
  max-width: 100px;
  display: flex;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-reviews {
  font-size: 10px;
  font-family: Gilroy-ExtraBold;
  color: #abaaba !important;
  margin-top: 0 !important;
  cursor: default !important;
}

.tooltip-wrapper-media {
  margin-bottom: 10px;
  min-width: 185px;
  width: 100%;
  font-size: 12px;
  font-family: "Gilroy-ExtraBold";
  font-weight: bold;
  position: relative;
  z-index: 999;
  padding: 10px 10px 5px 10px;
  border-radius: 2px;
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  background: #ffffff;
  .title {
    font-family: "Gilroy-ExtraBold";
    font-weight: bold;
  }
  .img-wrapper {
    margin: 10px 0;
    border: 1px solid #ececf0;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      max-width: 140px;
    }
    & > span {
      color: #ececf0;
    }
  }
  .show-banner {
    color: var(--color-theme-primary);
  }
}
.ranking-page-modal.ant-modal .ant-modal-content {
  border-radius: 8px;
}

.ant-btn-primary {
  color: #fff !important;
  background: var(--color-theme-primary) !important;
  border-color: var(--color-theme-primary) !important;
  &:hover {
    background: #40a9ff !important;
    border-color: #40a9ff !important;
  }
}

.ant-form-item-control-input-content {
  .ant-btn.ant-btn-primary {
    margin-top: 15px;
  }
}

.ant-form-item-label {
  font-weight: bolder;
}

.share-tooltip-wrapper {
  background-color: white;
  padding: 10px 15px;
  font-family: "Gilroy-Medium";
  font-size: 12px;
  min-width: 190px;
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  .title {
    font-family: "Gilroy-ExtraBold";
    font-weight: bold;
    margin-bottom: 10px;
  }
  .date {
    margin-bottom: 7px;
  }
  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .separator {
    height: 1px;
    width: 100%;
    background-color: #e0e0e4;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .title-with-color {
    display: flex;
    align-items: center;
    margin-top: 3px;
    .color {
      width: 11px;
      height: 11px;
      margin-right: 5px;
      border-radius: 2px;
    }
  }
}
.empty-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 35, 70, 0.06);
}
.select-color {
  width: 11px;
  height: 11px;
  margin-right: 5px;
  border-radius: 2px;
}
.tab-wrapper {
  margin-bottom: 16px;
  & span {
    font-family: Gilroy-ExtraBold;
    font-size: 18px;
    font-weight: 800;
    position: relative;
  }
}
.hidden {
  visibility: hidden;
}
.rc-virtual-list-holder {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
}

.selected-manufacture {
  background: #eef2f7;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-left: 15px;
  font-family: "Gilroy-Medium";
}

//Login/Reset Password Styles
.authorization-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-theme-primary);
  background-size: cover;
  .back-box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
    img {
      height: 20px;
    }
    span {
      margin-left: 10px;
      font-size: 18px;
      font-family: "Gilroy-Bold";
    }
  }
  .logo {
    width: 126px;
    height: 62px;
    position: absolute;
    left: 160px;
    top: 40px;
  }
  .logo-center {
    width: 126px;
    height: 62px;
    position: absolute;
    left: 45%;
    top: 45%;
  }
  .signin-box {
    max-width: 520px;
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    background: #fff;
  }
  .title {
    text-align: left;
    font-size: 36px;
    font-weight: 700;
    font-family: "Gilroy-ExtraBold";
  }
  form {
    padding: 50px 40px;
    width: 100%;
  }
  .ant-form-item {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 0;
  }
  .ant-form-horizontal .ant-form-item-control {
    width: 100%;
    height: 44px;
  }
  .ant-btn {
    font-family: "Gilroy-ExtraBold";
    width: 146px;
    height: 60px;
    background-color: var(--color-theme-primary) !important;
    margin-top: 25px;
    font-size: 16px;
    border-radius: 6px;
  }
  .ant-form-item-label > label {
    font-family: "Gilroy-Medium";
    color: #000;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      height: 34px;
    }
  }
  .ant-input {
    height: 44px;
  }
  .ant-input-suffix {
    display: flex;
    align-items: center;
  }
  .password {
    font-family: "Gilroy-Medium";
    font-size: 12px;
    color: #909090;
    margin-top: 10px;
    display: block;
    text-align: right;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease;
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease;
}

.fade-exit-done {
  opacity: 0;
}

.anim_mobile_selectors-enter {
  opacity: 0.1;
  transition: opacity 5000ms linear;
}

.anim_mobile_selectors-enter-active,
.anim_mobile_selectors-enter-done {
  opacity: 1;
}

.anim_mobile_selectors-exit {
  opacity: 1;
}

.anim_mobile_selectors-exit-active {
  opacity: 0.1;
  transition: opacity 5000ms linear;
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

// Animation class
.animate_content_close {
  @include keyframes(fade) {
    5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(1, 0.002);
    }
    50% {
      transform: scale(0.2, 0.002);
      opacity: 1;
    }
    100% {
      transform: scale(0.2, 0.002);
      opacity: 0;
    }
  }
  @include animate(fade, 8s, ease, 1);
}

.animate_content_open {
  @include keyframes(zoomIn) {
    0% {
      transform: scale(1, 1);
      opacity: 0;
    }
    5% {
      transform: scale(0.2, 0.002);
      opacity: 0;
    }
    20% {
      transform: scale(0.2, 0.002);
      opacity: 0.1;
    }
    40% {
      transform: scale(1, 0.002);
      opacity: 0.3;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0.9;
    }
  }
  @include animate(zoomIn, 2000ms, ease, 1);
}

.animate_fade_in {
  @include keyframes(fadeIn) {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  @include animate(fadeIn, 900ms, ease, 1);
}

.animate_fade_out {
  @include keyframes(fadeOut) {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @include animate(fadeOut, 500ms, ease, 1);
}

.ant-btn-primary.otp-item {
  margin-top: 0px !important;
}
.ant-btn.otp-btn {
  font-family: "Gilroy-ExtraBold";
  width: 146px;
  height: 60px;
  margin-top: 0px;
  font-size: 16px;
  border-radius: 6px;
  color: #3b4799 !important;
  background: #fff !important;
  border-color: #fff !important;
  &:hover {
    border-color: #3b4799 !important;
  }
}
