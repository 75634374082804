.wrapper-charts {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 740px;
  min-height: 130px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .chart-box {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Gilroy-ExtraBold";
      font-size: 12px;
      color: #000000;
      text-align: center;
      text-transform: capitalize;
      img {
        cursor: pointer;
      }
    }
    .relative-box {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .description {
        width: 100px;
        text-align: center;
        position: absolute;
        color: #5d6a7d;
        font-family: "Gilroy-Medium";
        z-index: 2;
        font-size: 10px;
      }
    }
  }
}

.title-hint {
  font-size: 14px;
  color: #000000;
  font-family: "Gilroy-Medium";
  margin-bottom: 25px;

  span {
    font-family: "Gilroy-ExtraBold";
  }
}

.current-retailer {
  margin-left: 15px;
  background: var(--color-theme-primary);
  color: #fff;
  text-transform: capitalize;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(4, 35, 70, 0.06), 0 2px 4px 0 rgba(72, 112, 246, 0.1);
  padding: 15px;
  height: 44px;
  cursor: pointer;
  span {
    margin-right: 10px;
  }
}

.legends-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  .legend-box {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: solid 1px #dbdbdd;
    padding: 6px 9px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    .color {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      margin-right: 5px;
    }
    .name {
      font-size: 10px;
      color: #5d6a7d;
      font-family: "Gilroy-Medium";
      text-transform: capitalize;
    }
  }
  .legend-box.active-legend {
    border: solid 1px var(--color-theme-primary);
  }
}

.row-charts-wrapper {
  position: relative;
  min-height: 130px;
  padding-bottom: 20px;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .chart-border {
    width: 100%;
    height: 4px;
    margin: 0 0 15px;
    border-radius: 100px;
    background-color: var(--color-theme-primary);
  }
  .item-chart-wrapper {
    width: 200px;
    position: relative;
    margin-right: 15px;
    background-color: var(--color-theme-primary-background);
    .inactive-box {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      z-index: 1;
      cursor: pointer;
    }
    .pie-chart-item {
      width: 200px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .title {
        color: #000000;
        font-size: 14px;
        font-family: "Gilroy-ExtraBold";
        margin-top: 20px;
        margin-left: 20px;
      }
      .pie-chart {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .percent {
          position: absolute;
          font-size: 10px;
          color: #5d6a7d;
          font-family: "Gilroy-Medium";
          top: 41px;
          left: 41px;
        }
      }
    }
  }
}

.viewing-trend-box {
  margin-top: 32px;
  .trend-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-size: 14px;
      color: #000000;
      font-family: "Gilroy-Medium";
      span {
        font-family: "Gilroy-ExtraBold";
      }
    }
  }
}

.currently-viewing-title {
  font-family: "Gilroy-Medium";
  color: #000000;
  font-size: 14px;
  margin: 32px 0;
  span {
    font-family: "Gilroy-ExtraBold";
    text-transform: capitalize;
  }
}

.filters-viewing {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .search-box {
    width: 280px;
    position: relative;
    font-size: 14px;
    color: #535456;
    font-family: "Gilroy-Medium";
    input {
      border: none;
      background-color: #eef2f7;
      width: 260px;
      height: 44px;
      border-radius: 2px;
      outline: none;
      padding-left: 40px;
      padding-right: 12px;
      &::placeholder {
        font-family: "Gilroy-Medium";
        color: #535456;
      }
    }
    img {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

.products-slider {
  width: 100%;
  padding: 0 44px;
  overflow: hidden;
  .ant-slider-mark-text {
    width: 100%;
  }
}

.currently-viewing-box {
  position: relative;
  .product-wrapper {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #e0e0e4;
    .product-box.main {
      border: none;
      .retailer {
        text-transform: capitalize;
      }
    }
    .product-box {
      max-width: 280px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;
      border-right: 1px solid #e0e0e4;
      .product-title {
        font-family: "Gilroy-Medium";
        color: #000000;
        font-size: 14px;
      }
      .product-image {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      .icons-box {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        img {
          margin: 4px 0;
        }
      }
    }
  }
}

.ant-slider-mark-text {
  font-size: 10px;
  font-family: "Gilroy-Medium";
  color: #737283;
}

.hint-text {
  font-size: 14px;
  font-family: "Gilroy-Medium";
  color: #000000;
  span {
    font-family: "Gilroy-ExtraBold";
  }
}

.hint-no-retailer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy-Medium";
  span {
    position: absolute;
    font-size: 16px;
    color: #9696a0;
  }
  .box-hint {
    display: flex;
    align-items: flex-end;
    opacity: 0.2;
    .item-hint {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 15px;
      img {
        width: 16px;
        height: 16px;
        margin-top: 8px;
      }
    }
  }
}

.total-retailer-chart {
  margin-top: 27px;
}

//ranking page
.tooltip {
  width: 200px;
  position: absolute;
  font-family: "Gilroy-Medium";
  padding: 10px;
  z-index: 999;
  border-radius: 2px;
  box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
  background: #ffffff;
  font-size: 12px;
  transition: all 0.5s ease-out;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -10px;
  }
  .name {
    font-family: "Gilroy-ExtraBold";
    text-transform: capitalize;
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    .change {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 14px;
      }
    }
  }
}
.total-term-chart-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .title {
    font-size: 14px;
    font-family: "Gilroy-Medium";
    color: #000000;
    margin-top: 25px;
    span {
      font-family: "Gilroy-ExtraBold";
      text-transform: capitalize;
    }
  }
}
.total-term-chart-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .change-box-wrapper {
    height: 100px;
    display: grid;
    align-items: center;
    margin-bottom: 5px;
    .change-box {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #d9dadc;
      padding-right: 20px;
      .change-column {
        position: relative;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        margin-left: 20px;
        padding: 0 5px;
        transition: all 0.5s ease-out;
        height: 2px;
        bottom: -1px;
        .change-item {
          position: relative;
          width: 8px;
        }
      }
    }
  }
  .averageShare-box-wrapper {
    display: grid;
    height: 130px;
    align-items: flex-end;
    .averageShare-box {
      display: flex;
      border-bottom: 1px solid #d9dadc;
      padding-right: 20px;
      .averageShare-column {
        position: relative;
        height: 5px;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        margin-left: 20px;
        padding: 0 5px;
        transition: all 0.5s ease-out;
        .averageShare-item {
          border-radius: 3px 3px 0px 0px;
          width: 8px;
        }
      }
    }
  }
}

.total-term-chart-box.main-term-box {
  padding-bottom: 0px;
  .change-box-wrapper .change-box {
    padding-right: 0px;
    .change-column {
      margin-left: 11px;
      padding: 0;
      .change-item {
        width: 10px;
      }
    }
  }
  .averageShare-box-wrapper .averageShare-box {
    padding-right: 0px;
    margin-bottom: 30px;
    .averageShare-column {
      margin-left: 11px;
      padding: 0;
      .averageShare-item {
        width: 10px;
      }
      .img {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 15px;
        left: -3px;
      }
    }
  }
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
}
.ranking-page-modal.ant-modal {
  width: 100% !important;
  display: flex;
  justify-content: center;
  top: 0;
  padding: 25px;
  .ant-modal-content {
    max-width: 1240px;
    width: 100%;
    display: inline-block;
    overflow-y: auto;
    .ant-modal-close {
      top: 25px;
      right: 25px;
    }
    .ant-modal-body {
      position: relative;
      padding: 120px 40px 50px 40px;
    }
  }
  .ant-modal-footer {
    display: none;
  }

  .table-popup-wrapper {
    width: 100%;
    overflow-x: auto;
    display: inline-grid;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
    .title-popup-ranking {
      position: absolute;
      top: 35px;
      left: 40px;
      color: #000000;
      font-size: 22px;
      font-family: "Gilroy-ExtraBold";
      span {
        text-transform: capitalize;
      }
    }
    .table-row-header {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e4;
      font-size: 12px;
      color: #9696a0;
      font-family: "Gilroy-Bold";
      .manufacturer {
        cursor: pointer;
        width: 220px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #9696a0;
        font-family: "Gilroy-Bold";
        padding: 22px 0;
      }
      .total {
        cursor: pointer;
        width: 155px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 22px 0;
        font-size: 12px;
        color: #9696a0;
        font-family: "Gilroy-Bold";
      }
      .retailer {
        cursor: pointer;
        padding: 22px 4px;
        width: 155px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 25px;
        }
      }
    }
    .table-row-body-wrapper {
      max-height: 600px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
    }
    .table-row-body {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e4;
      font-family: "Gilroy-Medium";
      font-size: 14px;
      .title {
        width: 220px;
        display: flex;
        align-items: center;
        padding: 22px 0;
        .color {
          width: 14px;
          height: 14px;
          border-radius: 10%;
          margin-right: 5px;
        }
      }
      .percent {
        padding: 22px 0;
        width: 155px;
        text-align: center;
        border-left: 1px solid #e0e0e4;
      }
    }
  }
  .title-popup-single-day {
    position: absolute;
    top: 28px;
    left: 40px;
    color: #000000;
    font-size: 22px;
    font-family: "Gilroy-ExtraBold";
    span {
      text-transform: capitalize;
    }
  }
  .screenshot-wrapper {
    max-height: 600px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
    img {
      max-width: 100%;
    }
  }
}
.ranking-page-modal.bottom.ant-modal {
  .ant-modal-body {
    position: relative;
    padding: 40px;
  }
  .ant-modal-close {
    top: 40px;
  }
}

//ranking page
.wrapper-title-popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  margin-bottom: 30px;
  .product-title-popup {
    .title {
      color: #000000;
      font-size: 22px;
      font-family: "Gilroy-ExtraBold";
    }
    .subtitle {
      font-family: "Gilroy-Medium";
      font-size: 14px;
      color: #000000;
    }
  }
}
.popup-setting-box {
  display: flex;
  align-items: center;
  .compare-data-popup {
    padding: 12px 15px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    margin-right: 15px;
    cursor: pointer;
  }
  .controls-box {
    display: flex;
  }
}
.trend-perion-wrapper {
  position: relative;
  .trend-perion {
    background: rgb(238, 242, 247);
    color: #000;
    padding: 11px 15px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    margin-right: 15px;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    left: 0;
    top: 50px;
    width: 220px;
    z-index: 2;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    padding: 16px;
    .description {
      font-family: "Gilroy-Bold";
      color: #9696a0;
      font-size: 12px;
    }
    .period-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-period {
        width: 82px;
        margin-top: 15px;
        font-size: 14px;
        padding: 12px 14px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .dropdown.retailer {
    right: 12px;
    left: auto;
  }
}
.popup-setting-box.singleDay {
  justify-content: space-between;
  margin-bottom: 32px;
}

.icon-btn {
  border-radius: 4px;
  background-color: #eef2f7;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 24px;
  }
}
.no-screen {
  color: #abaaba;
  font-family: "Gilroy-ExtraBold";
  font-size: 10px;
  padding: 12px 10px;
}
.table-header.main {
  justify-content: space-between;
  .price-sort {
    padding-right: 0;
    padding-left: 20px;
    justify-content: flex-start;
  }
  .retailer-sort {
    justify-content: center;
  }
}
.table-header {
  display: flex;
  align-items: center;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  color: #9696a0;
  padding-bottom: 20px;
  border-bottom: 2px solid #e0e0e4;
  .rank-sort {
    max-width: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .title-sort {
    width: 100%;
    max-width: 464px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
  }
  .discription-sort {
    width: 100%;
    max-width: 366px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
  }
  .price-sort {
    width: 100%;
    max-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    cursor: pointer;
  }
}
.table-body.main {
  .table-item {
    justify-content: space-between;
    .right-wrapper {
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
      padding: 0 5px;
    }
    .right-wrapper.all {
      justify-content: center;
    }
  }
}
.table-body {
  max-height: 518px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .table-item.selectedProduct {
    background: #f9f9fc;
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-left: 10px solid var(--color-theme-primary);
      border-bottom: 7px solid transparent;
    }
  }
  .table-item {
    position: relative;
    display: flex;
    align-items: stretch;
    .rank {
      width: 100%;
      max-width: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #000000;
      font-family: "Gilroy-Medium";
      img {
        height: 20px;
        margin-left: 6px;
      }
    }
    .title-wrapper.selectedManufacture {
      &:before {
        content: "";
        position: absolute;
        left: 20px;
        top: -20px;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--color-theme-primary);
      }
      .titles-box {
        .title {
          color: var(--color-theme-primary);
          font-family: "Gilroy-ExtraBold";
        }
      }
    }
    .title-wrapper {
      position: relative;
      max-width: 464px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;
      border-right: 1px solid #e0e0e4;
      border-left: 1px solid #e0e0e4;
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      .titles-box {
        .title {
          color: #000000;
          font-family: "Gilroy-Medium";
          font-size: 14px;
        }
        .subtitle {
          font-size: 12px;
          font-family: "Gilroy-Medium";
          color: #000;
        }
      }
    }
    .description-wrapper {
      max-width: 366px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;
    }
    .description-box {
      width: 100%;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      padding: 2px;
      padding-left: 5px;
      font-family: "Gilroy-ExtraBold";
      .no-promotion {
        color: #9696a0;
        text-align: left;
        display: block;
      }
    }
    .price {
      max-width: 180px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #000000;
      font-family: "Gilroy-Medium";
      border-right: 1px solid #e0e0e4;
      border-left: 1px solid #e0e0e4;
    }
  }
}
.table-footer.summary {
  margin-top: 40px;
  .shadow-box {
    width: 103%;
    left: -22px;
  }
}
.table-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  .compare-hint {
    .title {
      font-size: 18px;
      color: #000000;
      font-family: "Gilroy-ExtraBold";
    }
    .hint {
      font-size: 14px;
      color: #000000;
      font-family: "Gilroy-Medium";
    }
  }
  .compare-btn {
    border-radius: 4px;
    padding: 14px 16px;
    font-size: 14px;
    color: #ffffff;
    font-family: "Gilroy-ExtraBold";
    cursor: pointer;
  }
  .shadow-box {
    position: absolute;
    opacity: 0.04;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0) 50%);
    height: 20px;
    width: 107%;
    top: 0;
    left: -42px;
  }
}

.trend-single-day {
  margin-top: 32px;
}
.search-term-single-day {
  .table-header {
    .retailer-sort {
      max-width: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .search-term-sort {
      max-width: 140px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .table-body {
    max-height: 100%;
    overflow: visible;
    .search-term {
      max-width: 140px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-right: 1px solid #e0e0e4;
      border-left: 1px solid #e0e0e4;
      font-family: Gilroy-Medium;
      font-size: 14px;
      color: #000000;
    }
    .retailer {
      max-width: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.right-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border: 2px solid #8a99a8;
  }
  .ant-checkbox-inner::after {
    width: 7px;
    height: 12px;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
}

.pagimation-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  .pagination-controls {
    position: static;
  }
  .per-page-box {
    display: flex;
    align-items: center;
    float: right;
    position: absolute;
    right: 0;
    top: 35px;
  }
}

.title-terms {
  color: #000000;
  font-size: 14px;
  font-family: Gilroy-Medium;
  margin-bottom: 32px;
  span {
    font-family: Gilroy-ExtraBold;
  }
}
.title-terms.all {
  p {
    display: inline;
    span {
      text-transform: capitalize;
    }
  }
}
.collapse-terms-wrapper {
  .table-header {
    padding-left: 6px;
    padding-right: 6px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 30px;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse {
    border: none;
    background: inherit;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
  }
  .table-body {
    max-height: 910px;
  }
}
.panel-header-term {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon-collapse {
    max-width: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .retailer {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 464px;
    font-size: 14px;
    color: #000000;
    font-family: Gilroy-ExtraBold;
    padding-left: 25px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .promotions {
    width: 100%;
    max-width: 366px;
    padding-left: 20px;
    font-size: 14px;
    color: #000000;
    font-family: Gilroy-ExtraBold;
  }
  .price {
    max-width: 180px;
    width: 100%;
    padding-left: 20px;
    font-size: 14px;
    color: #000000;
    font-family: Gilroy-ExtraBold;
  }
}
.learn-more {
  width: 102px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--color-theme-primary);
  color: #fff;
  font-family: "Gilroy-ExtraBold";
  margin-top: 25px;
  cursor: pointer;
}

.printscreen {
  padding: 8px;
  background: #eef2f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.highcharts-tooltip {
  & > span {
    .search-term-tooltip {
      width: 200px;
      position: relative;
      font-family: "Gilroy-Medium";
      padding: 10px;
      z-index: 999;
      border-radius: 2px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      background: #ffffff;
      font-size: 12px;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #fff;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: -10px;
      }
      .title-name {
        font-family: "Gilroy-ExtraBold";
        text-transform: capitalize;
      }
      .tooltip-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .change {
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            height: 14px;
          }
        }
      }
    }
  }
}

//portal
.cutom-tooltip-box {
  width: 240px;
  max-height: 240px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 12px;
  z-index: 10;
  padding: 0 15px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .show-prod {
    color: var(--color-theme-primary);
    font-family: "Gilroy-ExtraBold";
    font-size: 12px;
    cursor: pointer;
    margin-top: 15px;
  }
  .title-name {
    color: #000000;
    font-family: "Gilroy-ExtraBold";
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .tooltip-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    font-family: "Gilroy-Medium";
    .change {
      display: flex;
      align-items: center;
      img {
        height: 16px;
        margin-left: 15px;
      }
    }
    .box {
      display: flex;
      align-items: center;
    }
    .color {
      width: 14px;
      height: 14px;
      border-radius: 10%;
      margin-right: 5px;
    }
    .name {
      font-size: 12px;
      color: #000000;
    }
    .value {
      margin-left: 10px;
    }
  }
}

.lables-compare-product {
  display: flex;
  margin-top: 20px;
  .lable-product {
    position: relative;
    width: 186px;
    display: flex;
    border-radius: 4px;
    margin-right: 6px;
    padding: 4px;
    cursor: pointer;
    .color-box {
      position: absolute;
      left: 14px;
      top: 22px;
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    img {
      width: 24px;
      height: 24px;
    }
    div {
      font-family: "Gilroy-Medium";
      font-size: 10px;
      color: #5d6a7d;
      margin-left: 8px;
    }
  }
}

.onlyProducts {
  width: auto;
  background: #eef2f7;
  border: none;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  font-family: "Gilroy-Medium";
}

.select-retailers {
  color: #000000;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  span {
    font-family: "Gilroy-ExtraBold";
  }
}

.search-terms-control {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .search-terms-dropdown {
    p {
      font-size: 12px;
      color: #000000;
      font-family: "Gilroy-ExtraBold";
      margin-bottom: 5px;
    }
    &:last-child > div {
      margin-left: 0;
    }
  }
}

.empty-image {
  margin: 0 auto;
  display: block;
  padding-bottom: 180px;
}

.search-term-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .search-term-box {
    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    border: solid 1px #e0e0e4;
    margin-top: 25px;
    @media (max-width: 1920px) {
      max-width: 590px;
    }
    .panel-header-term {
      padding-bottom: 15px;
      border-bottom: 2px solid #e0e0e4;
    }
    .table-header {
      padding-top: 15px;
    }
    .panel-header-term .retailer {
      padding-left: 0;
    }
    .panel-header-term .promotions {
      padding-left: 88px;
    }
    .printscreen-absolute {
      max-width: 138px;
      &:last-child > div {
        margin-left: 10px;
      }
    }
    .table-header .discription-sort,
    .table-header .title-sort {
      padding-left: 15px;
    }
    .table-item .description-box {
      font-size: 10px;
    }
    .table-item .right-wrapper {
      max-width: 70px;
      border-left: 1px solid #e0e0e4;
    }
    .table-header .rank-sort {
      max-width: 50px;
    }
    .table-item .rank {
      max-width: 70px;
      font-size: 12px;
    }
    .table-item .title-wrapper {
      padding: 6px 12px;
    }
    .table-item .title-wrapper .titles-box .title {
      font-size: 12px;
    }
    .table-item .title-wrapper img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
  }
}

.ranking-separate-line {
  height: 1px;
  width: 100%;
  background: #e0e0e4;
  margin: 10px 0;
}

.retailer-boxes-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: default;
  .retailer-box {
    height: 36px;
    width: 55px;
    border-radius: 4px;
    border: solid 1px #dfdfea;
    padding: 6px;
    cursor: move;
    margin-top: 10px;
  }
  .retailer-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    span {
      font-size: 12px;
      color: #9696a0;
      font-family: "Gilroy-Bold";
    }
  }
}

.sponsored-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 25px;
  margin-right: 5px;
  & > div > label {
    display: flex;

    & :last-child {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
}
