.export-modal {
  max-width: 940px;
  width: 100% !important;
  top: 0;
  height: -webkit-fill-available;
  .ant-modal-header {
    font-family: "Gilroy-Bold";
    color: #000000;
    .ant-modal-title {
      font-size: 18px;
    }
  }
  .ant-modal-body {
    padding: 40px 30px;
  }
  .ant-modal-footer {
    display: none;
  }
  .selects-box {
    flex-wrap: wrap;
    .wrapper-custom-select-box {
      margin-top: 14px;
    }
    .custom-select-dropdown {
      border: 1px solid #eef2f7;
    }
  }
  .export-tab {
    position: relative;
    max-height: 720px;
    overflow-y: auto;
    padding-left: 2px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
    .select-calculation-box {
      .select-calculation {
        max-width: 645px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .export-hint + div {
        margin: 0;
      }
    }

    .share-dropdown-wrapper .share-dropdown {
      max-width: 280px;
      justify-content: space-between;
      // margin-top: 10px;
    }
    .user-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding: 4px 0;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .ant-dropdown-menu {
      border: 1px solid #eef2f7;
    }

    .export-hint {
      font-size: 14px;
      color: #000000;
      font-family: "Gilroy-Medium";
    }
    .export-label {
      font-size: 12px;
      color: #000000;
      font-family: "Gilroy-ExtraBold";
      margin-bottom: 8px;
    }
    .export-input {
      max-width: 280px;
      width: 100%;
      height: 44px;
      border-radius: 2px;
      background-color: #eef2f7;
      border: none;
      outline: none;
      font-size: 14px;
      font-family: "Gilroy-Medium";
      padding: 0 20px;
    }
    .add-email-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      svg {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
    .add-email-box + .error-hint {
      color: red;
      position: relative;
      top: -12px;
      font-size: 12px;
    }
    .export-button {
      display: block;
      max-width: 280px;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      background-color: var(--color-theme-primary);
      margin-top: 40px;
      color: #fff;
      border: none;
      outline: none;
      font-family: "Gilroy-ExtraBold";
      font-size: 14px;
    }
    .export-button[disabled] {
      background-color: var(--color-theme-primary);
      opacity: 0.6;
    }
    .export-group-input {
      display: flex;
      .group-input {
        margin-right: 25px;
        width: 100%;
        // & > :last-child {
        // margin-top: 20px;
        // }
      }
    }
    .ant-select-selector {
      // min-width: 288px;
    }

    .export-table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .export-column {
        font-family: "Gilroy-Bold";
        color: #9696a0;
        font-size: 12px;
      }
    }
    .export-table-body {
      max-height: 520px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
      .report-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .report-options {
          width: 240px;
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
          div {
            height: 44px;
            width: 44px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
          }
          .disabled-btn {
            cursor: not-allowed;
            opacity: 0.1;
          }
        }
      }
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .export-table-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;
      padding-right: 15px;
      border-bottom: 1px solid #e0e0e4;
      .export-period,
      .export-name,
      .export-report,
      .export-retailer {
        font-family: "Gilroy-Medium";
        color: #000000;
        font-size: 14px;
      }
      .export-controls {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          border-radius: 4px;
          cursor: pointer;
          background-color: #eef2f7;
        }
      }
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 22px;
          height: 22px;
          border: 2px solid #d9d9d9;
        }
      }
      .ant-checkbox-checked::after {
        border: 1px solid var(--color-theme-primary);
      }
      .ant-checkbox-inner::after {
        left: 33%;
      }
      & > :last-child {
        font-family: "Gilroy-Medium";
        font-size: 14px;
        color: #000000;
      }
    }
    .ant-radio-group {
      max-width: 520px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .ant-radio-wrapper {
        display: flex;
        align-items: center;
        width: 140px;
        font-weight: bold;
        margin-top: 10px;
        margin-right: 25px;
      }
    }
  }

  //report
  .export-report {
    max-width: 260px;
    width: 100%;
    font-family: "Gilroy-Bold";
    color: #9696a0;
    font-size: 12px;
  }
  .export-retailer {
    max-width: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Gilroy-Bold";
    color: #9696a0;
    font-size: 12px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.export-item {
  display: flex;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 14px;
  .number {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--color-theme-primary);
    color: #fff;
    font-size: 12px;
    font-family: "Gilroy-ExtraBold";
    margin-right: 15px;
  }
  .text {
    font-size: 18px;
    font-family: "Gilroy-ExtraBold";
    color: #000000;
  }
}

.export-modal.navigation {
  max-width: 1240px;
  width: 100%;
  padding: 0;
  z-index: 9999;
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close-x {
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 22px;
    }
  }
  .ant-modal-header {
    border-radius: 8px;
    padding: 35px 30px;
    .ant-modal-title {
      font-size: 22px;
    }
  }
}

.export-modal.otp {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 35%;
  max-width: 540px;
  width: 100%;
  padding: 0;
  z-index: 9999;
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close-x {
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 22px;
    }
  }
}
.export-modal.download {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 35%;
  max-width: 540px;
  width: 100%;
  padding: 0;
  z-index: 9999;
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close-x {
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 22px;
    }
  }
  .ant-modal-header {
    border-radius: 8px;
    padding: 35px 30px;
    .ant-modal-title {
      font-size: 22px;
    }
  }
}

.section-summary {
  .rc-virtual-list-holder {
    max-height: 200px !important;
  }
}

.back-btn {
  background-color: #eef2f7;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
.wrapper-filter-box {
  position: relative;
  .filter-title {
    width: 280px;
    border: none;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 12px 15px;
    font-family: "Gilroy-ExtraBold";
    margin-top: 14px;
    &:hover {
      color: #000;
    }
    &:focus {
      color: #000;
    }
    &:after {
      display: none !important;
    }
  }
  .filter-title[disabled] {
    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .date-title {
    color: #9696a0;
    font-size: 12px;
    font-family: "Gilroy-Bold";
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .filter-dropdown {
    max-width: 854px;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    border: 1px solid #eef2f7;
    z-index: 99;
    overflow: hidden;
    padding: 15px;
    top: 50px;
    left: 0;
    .date-box {
      border-radius: 4px;
      font-size: 14px;
      padding: 6px 14px;
      cursor: pointer;
      margin-right: 10px;
    }
    & > :last-child {
      margin-right: 0px;
    }
    .calendar-box {
      height: 320px;
      position: relative;
      .range-box {
        position: relative;
        height: 100%;
        & > :last-child {
          bottom: 0;
          top: auto !important;
        }
        .ant-picker-dropdown {
          position: static !important;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 15px;
      width: 95%;
      margin: 0 auto;
      left: 0;
      right: 0;
      align-items: center;
      & > :first-child {
        font-size: 16px;
        color: var(--color-theme-primary);
        border: none;
        font-family: "Gilroy-ExtraBold";
        cursor: pointer;
      }
      .ant-btn.export-button {
        width: 120px;
        margin: 0;
      }
    }
  }
}
.back-btn-box {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  div {
    height: 34px;
    width: 34px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eef2f7;
    margin-right: 10px;
    img {
      height: 18px;
      width: 18px;
    }
  }
  span {
    font-family: "Gilroy-ExtraBold";
    font-size: 16px;
  }
}
.tab-label-notification {
  display: flex;
}
.share-dropdown.ant-dropdown-trigger[disabled] {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  background: #eef2f7;
}
