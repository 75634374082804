@mixin alertExtraBoldText {
  font-family: "Gilroy-ExtraBold";
  color: #000000;
  font-size: 14px;
}

@mixin flexCenterBetwwen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
}

@mixin flexBetwwen {
  display: flex;
  justify-content: space-between;
}

@mixin alertBoldText {
  color: #9696a0;
  font-size: 12px;
  font-family: "Gilroy-Bold";
}

@mixin colorBox {
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

@mixin alertMediumText {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #000000;
}

.export-item.alert {
  margin-bottom: 20px;
}

.subLabel-alert {
  color: #000000;
  font-family: "Gilroy-ExtraBold";
  font-size: 12px;
  margin-bottom: 10px;
  span {
    color: #9696a0;
    font-family: "Gilroy-Bold";
  }
}

.alerts-controls-box {
  @include flexCenterBetwwen;
  .search-location {
    margin: 0;
  }
  .create-btn {
    width: 280px;
    height: 44px;
    @include flexCenter;
    justify-content: center;
    background: var(--color-theme-primary);
    border-radius: 4px;
    font-family: "Gilroy-ExtraBold";
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
  }
}

.selects-box {
  @include flexCenter;
  .wrapper-custom-select {
    margin-right: 16px;
  }
  @media (max-width: 1692px) {
    flex-wrap: wrap;
  }
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}

//components
.wrapper-custom-select-box {
  position: relative;
  @media (max-width: 1692px) {
    margin-bottom: 25px;
  }
  .wrapper-custom-select {
    .custom-select-label {
      width: 280px;
      height: 44px;
      border: none;
      @include flexCenterBetwwen;
      border-radius: 4px;
      padding: 12px 15px;
      font-family: "Gilroy-Medium";
      &:hover {
        color: #000;
      }
      &:focus {
        color: #000;
      }
      &:after {
        display: none !important;
      }

      .select-label {
        @include flexCenter;
        font-family: "Gilroy-Medium";
        font-size: 14px;
        width: 100%;
        .selected-length {
          margin-left: 5px;
        }
        .ant-checkbox-wrapper {
          display: flex;
        }
      }
      .selected-label {
        font-family: "Gilroy-ExtraBold";
      }
      .hint-selected-item {
        width: 160px;
        @include flexCenter;
        justify-content: flex-end;
        img,
        div {
          max-width: 16px;
          width: 100%;
          height: 16px;
        }
        .selected-list {
          width: 100%;
          max-width: 160px;
          position: relative;
          @include flexCenter;
          justify-content: flex-end;
          margin: 0 10px;
          & > img {
            top: 0;
            position: absolute;
          }
        }
      }
    }
    .custom-select-label[disabled] {
      &:hover {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .ant-checkbox-wrapper {
      @include flexCenter;
      width: 280px;
      height: 44px;
      border-radius: 4px;
      padding: 12px 15px;
      cursor: pointer;
      font-family: "Gilroy-Medium";
      & > :last-child {
        width: 100%;
        @include flexCenterBetwwen;
        padding-right: 0;
      }
    }
  }
  .set-details {
    font-family: "Gilroy-Bold";
    font-size: 12px;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
    background: none;
    border: none;
  }
  .custom-select-dropdown {
    // position: absolute;
    top: 50px;
    left: 0;
    // width: 320px;
    border-radius: 4px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    z-index: 5;
    padding-top: 1px;
    .search-item {
      position: relative;
      margin: 16px 16px 22px 16px;
      border-radius: 2px;
      height: 44px;
      img {
        position: absolute;
        top: 10px;
        left: 15px;
      }
      input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-family: "Gilroy-Medium";
        border: none;
        background-color: #eef2f7;
        border-radius: 2px;
        outline: none;
        padding-left: 50px;
      }
    }
    .count-items {
      @include flexBetwwen;
      padding: 0 16px;
      color: #9696a0;
      font-size: 12px;
      font-family: Gilroy-Bold;
      margin-bottom: 16px;
    }
    .select-lists {
      max-height: 360px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
      .select-item-box {
        @include flexCenter;
        margin: 0 16px 8px 16px;
        padding: 12px;
        border-radius: 4px;
        border: solid 1px #e6ecf0;
        font-size: 14px;
        margin-top: 8px;
        cursor: pointer;
        .select-item {
          @include flexCenter;
          img {
            margin-right: 12px;
            width: 16px;
            height: 16px;
          }
          .select-color {
            @include colorBox;
          }
        }
        .select-name {
          line-height: 18px;
          font-family: "Gilroy-Medium";
          color: #000000;
          text-transform: capitalize;
        }
        .select-count {
          color: #9696a0;
          font-size: 14px;
          margin-left: 5px;
          font-family: "Gilroy-Medium";
        }
      }
      .selected-item-box {
        border: solid 2px var(--color-theme-primary) !important;
        .select-name {
          color: var(--color-theme-primary) !important;
          font-family: "Gilroy-ExtraBold";
        }
      }
    }
    .slider-value {
      font-family: "Gilroy-Bold";
      color: #9696a0;
      font-size: 12px;
      margin-bottom: 14px;
    }
    .slider-wrapper {
      max-width: 350px;
      padding: 25px 16px 16px 16px;
      & > :last-child {
        // margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
      .title {
        font-family: "Gilroy-Bold";
        color: #9696a0;
        font-size: 12px;
        margin-bottom: 14px;
      }
    }
  }
  .wrapper-control-btn {
    max-width: 280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .primary-btn {
      width: 120px;
      height: 44px;
      border-radius: 4px;
      background-color: #3b4799;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Gilroy-ExtraBold";
      color: #000000;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      margin-right: 15px;
    }
    .disabled {
      background-color: var(--color-theme-primary);
      cursor: not-allowed;
    }
    .default-btn {
      width: 120px;
      height: 44px;
      border-radius: 4px;
      background-color: #eef2f7;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Gilroy-ExtraBold";
      color: #000000;
      font-size: 14px;
      color: #3b4799;
      cursor: pointer;
    }
  }
}
.control-select-btn {
  @include flexBetwwen;
  align-items: center;
  padding: 20px 16px;
  border-top: 1px solid #e0e0e4;
  div {
    font-family: "Gilroy-ExtraBold";
    color: var(--color-theme-primary);
    font-size: 16px;
    cursor: pointer;
  }
  .apply {
    width: 70px;
    height: 44px;
    @include flexCenter;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--color-theme-primary);
    color: #fff;
    border: none;
    outline: none;
    font-family: "Gilroy-ExtraBold";
    cursor: pointer;
  }
}

.wrapper-slider-inputs {
  @include flexCenterBetwwen;
  margin-top: 20px;
  .separete {
    font-family: "Gilroy-Medium";
  }
  input {
    width: 100px;
    height: 44px;
    border-radius: 2px;
    background-color: #eef2f7;
    font-family: "Gilroy-Medium";
    font-size: 14px;
    color: #000;
    border: none;
    outline: none;
    padding-left: 20px;
  }
}

.alert-name {
  width: 280px;
  height: 44px;
  border-radius: 2px;
  background-color: #eef2f7;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 14px;
  padding-left: 20px;
  outline: none;
}

.alert-message {
  max-width: 100%;
  width: 896px;
  border-radius: 2px;
  background-color: #eef2f7;
  outline: none;
  border: none;
  font-size: 14px;
  padding: 16px 20px;
}

.schedule-wrapper {
  width: 100%;
  @include flexCenter;
  flex-wrap: wrap;
  .schedule-frequency {
    max-width: 280px;
    width: 100%;
    margin-right: 16px;

    & > :first-child {
      margin-left: 0;
      font-family: "Gilroy-Medium";
    }
    .ant-select {
      max-width: 280px;
      width: 100%;
    }
  }
  .share-dropdown-wrapper {
    max-width: 280px;
    width: 100%;
    margin-right: 16px;
    .share-dropdown {
      @include flexBetwwen;
      padding: 15px 15px;
      height: 44px;
      font-family: "Gilroy-Medium";
      font-size: 14px;
    }
    .ant-dropdown-menu {
      display: flex;
      flex-direction: column;
      & > :last-child {
        margin-bottom: 0;
      }
      .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }
}

.wrapper-time {
  position: relative;
  max-width: 280px;
  width: 100%;
  .time-value {
    max-width: 280px;
    width: 100%;
    height: 44px;
    @include flexCenterBetwwen;
    background: #eef2f7;
    color: #000;
    padding: 15px 15px;
    font-family: "Gilroy-Medium";
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  .time-dropdown {
    position: absolute;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 25px;
    padding: 25px 0 12px 0;
    .time-box {
      display: flex;
      justify-content: center;
      .hours {
        .hours-box {
          border-top: 1px solid #e0e0e4;
          border-right: 1px solid #e0e0e4;
          padding-top: 10px;
          padding-right: 10px;
          .par-time {
            width: 130px;
            display: flex;
            flex-wrap: wrap;
          }
          .date-box {
            display: flex;
          }
        }
      }
      .minutes {
        .minute-box {
          border-top: 1px solid #e0e0e4;
          padding-top: 10px;
          padding-left: 10px;
          display: flex;
          flex-wrap: wrap;
          max-width: 80px;
          width: 100%;
        }
      }
    }
    .title {
      color: #9696a0;
      font-family: "Gilroy-Bold";
      font-size: 12px;
      text-align: center;
      margin-bottom: 10px;
    }
    .time-item {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      padding: 4px;
      margin: 4px;
      cursor: pointer;
      font-family: "Gilroy-Medium";
      color: #000000;
      font-size: 13px;
    }
    .control {
      display: flex;
      padding: 0 16px;
      border-top: 1px solid #e0e0e4;
      padding-top: 8px;
    }
  }
}

.alert-item-wrapper {
  padding: 10px 0;
  .alert-header {
    @include flexBetwwen;
    .name {
      @include flexCenter;
      cursor: pointer;
      span {
        @include alertExtraBoldText;
        margin-left: 20px;
      }
      svg {
        cursor: pointer;
      }
    }
    .info {
      max-width: 260px;
      width: 100%;
      @include flexCenterBetwwen;
      .date {
        & > :first-child {
          font-family: "Gilroy-ExtraBold";
          color: #abaaba;
          font-size: 10px;
          margin-bottom: 6px;
        }
        & > :last-child {
          @include alertExtraBoldText;
        }
      }
      .control {
        max-width: 98px;
        width: 100%;
        @include flexCenterBetwwen;
        div {
          padding: 10px;
          border-radius: 4px;
          background-color: #eef2f7;
          cursor: pointer;
        }
      }
    }
  }
  .alert-body {
    .body-header {
      @include flexCenterBetwwen;
      padding-bottom: 25px;
      border-bottom: 2px solid #e0e0e4;
      border-top: 2px solid #e0e0e4;
      margin-top: 8px;
    }
    .body-body {
      @include flexBetwwen;
      .title-item {
        @include flexCenter;
        margin-bottom: 16px;
        div {
          margin-left: 12px;
          text-transform: capitalize;
          @include alertMediumText;
        }
      }
    }
    .filters-box-title {
      width: 30%;
      padding-top: 25px;
      span {
        @include alertBoldText;
      }
      .retailer {
        img {
          margin-right: 10px;
          width: 25px;
        }
        & > :last-child {
          margin-right: 0;
        }
      }
      .categories {
        margin-top: 30px;
        .title {
          @include flexCenter;
          margin-bottom: 18px;
          div {
            @include alertMediumText;
            margin-left: 9px;
          }
        }
        .category {
          display: flex;
          margin-bottom: 6px;
          .name {
            @include alertMediumText;
          }
          .color {
            @include colorBox;
          }
        }
        .total {
          @include alertExtraBoldText;
          margin-top: 12px;
        }
      }
    }
    .metrics-box-title {
      width: 35%;
      padding-top: 25px;
      span {
        @include alertBoldText;
      }
      .metrics-wrapper {
        display: flex;
        margin-bottom: 30px;
        .metric-box {
          max-width: 190px;
          width: 100%;
          .changeRange {
            @include flexCenter;
            @include alertExtraBoldText;
            margin-top: 12px;
          }
        }
      }
    }
    .delivery-box-title {
      width: 20%;
      padding-top: 25px;
      span {
        @include alertBoldText;
      }
    }
    .schedule-box-title {
      width: 15%;
      padding-top: 25px;
      span {
        @include alertBoldText;
      }
      .time {
        @include alertExtraBoldText;
      }
    }
  }

  .item-table {
    line-height: 24px;
    @include alertMediumText;
    .discount-item {
      margin-left: 5px;
      @include alertExtraBoldText;
    }
  }
}

.delete-alert-hint {
  // padding: 0 16px;
  .title {
    @include alertExtraBoldText;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
  }
  .wrapper-btn {
    @include flexCenterBetwwen;
    width: 200px;
    margin-top: 25px;
    .cancel {
      width: 78px;
      border-radius: 4px;
      background-color: #eef2f7;
      color: var(--color-theme-primary);
      font-size: 14px;
      font-family: "Gilroy-ExtraBold";
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 44px;
    }
    .remove {
      min-width: 105px;
      border-radius: 4px;
      background: #fe6a68;
      color: #ffffff;
      font-size: 14px;
      font-family: "Gilroy-ExtraBold";
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 44px;
    }
  }
}
.share-input {
  max-width: 280px;
  width: 100%;
  height: 44px;
  border-radius: 2px;
  background-color: #eef2f7;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Gilroy-Medium";
  padding: 0 20px;
}

.add-email-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}
.add-email-box + .error-hint {
  color: red;
  position: relative;
  top: -12px;
  font-size: 12px;
}

.alert-btn-wrapper {
  @include flexCenter;
  margin-top: 50px;
  .nudge-btn {
    width: 280px;
    height: 44px;
    border-radius: 4px;
    background-color: var(--color-theme-primary);
    @include flexCenter;
    justify-content: center;
    @include alertExtraBoldText;
    color: #fff;
    cursor: pointer;
    margin-right: 15px;
  }
  .test-btn {
    width: 280px;
    height: 44px;
    border-radius: 4px;
    background-color: #eef2f7;
    @include flexCenter;
    justify-content: center;
    @include alertExtraBoldText;
    color: var(--color-theme-primary);
    cursor: pointer;
  }
}

.alert-field {
  width: 100%;
  max-width: 280px;
  margin-right: 16px;
}
.is-empty-field {
  width: 100%;
  max-width: 280px;
  margin-right: 16px;
  .subLabel-alert {
    color: #fe6a68;
  }
  .alert-name,
  .ant-select-selector,
  .share-dropdown,
  .time-value,
  .export-input {
    border: 1px solid #fe6a68 !important;
    &::placeholde {
      color: #fe6a68;
    }
  }
  .error-hint {
    font-family: "Gilroy-Medium";
    font-size: 12px;
    color: #fe6a68;
    margin-top: 6px;
  }
}

.wrapper-custom-select {
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 2px solid #8a99a8;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 3px solid #fff;
    border-top: 0;
    border-left: 0;
    left: 5px;
    top: 9px;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
}

.custome-checkbox {
  font-family: "Gilroy-Medium";
  color: #000000;
  font-size: 14px;
  margin-bottom: 15px;
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 2px solid #8a99a8;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 3px solid #fff;
    border-top: 0;
    border-left: 0;
    left: 5px;
    top: 9px;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
}
