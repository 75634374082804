.container {
  background-color: #3b4799;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
}

.maintenance-logo {
  margin-bottom: 40px;
  animation: float 6s ease-in-out infinite;
}

.message-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.gear-icon {
  width: 30px;
  height: 30px;
  animation: spin 4s linear infinite;
}

.message {
  color: white;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
