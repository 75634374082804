.locations-wrapper {
  position: relative;
  display: -webkit-box;
  overflow-x: auto;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .location-wrapper-box {
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 38px);
      background: #d2d3d5;
      position: absolute;
      top: 30px;
      left: 132px;
    }
  }
  .location-wrapper {
    max-width: 400px;
    position: relative;
    width: 100%;
    margin-right: 15px;
    border-radius: 4px;
    background-color: #f7f8fb;
    padding: 24px 16px;
    .location-title {
      display: flex;
      align-items: center;
      background-color: #fafbfd;
      border-radius: 2px;
      padding: 10px 18px;
      text-transform: capitalize;
      cursor: pointer;
      img {
        margin-right: 10px;
        width: 25px;
      }
      div {
        color: #000000;
        font-size: 14px;
        font-family: "Gilroy-Medium";
      }
    }
    .pie-charts {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .pie-chart {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .percent {
        position: absolute;
        font-size: 12px;
        font-family: "Gilroy-Medium";
        color: #5d6a7d;
        top: 48px;
      }
      .pie-title {
        color: #000000;
        font-size: 14px;
        text-align: center;
        font-family: "Gilroy-Medium";
      }
    }
    .locations-list {
      margin-top: 25px;
      max-height: 420px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
      .location-item {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .location-row {
          position: relative;
          max-width: 100px;
          width: 100%;
          height: 6px;
          border-radius: 100px;
          background-color: rgba(0, 0, 0, 0.15);
          margin-right: 20px;
          div {
            position: absolute;
            height: 6px;
            border-radius: 100px;
            background-color: #f14758;
          }
        }
        .location-category {
          display: flex;
          align-items: center;
          font-family: "Gilroy-Medium";
          color: #000000;
          font-size: 12px;
          cursor: pointer;

          span {
            color: #5d6a7d;
            margin-left: 4px;
          }
          img {
            margin-right: 4px;
          }
        }
      }
      .location-item.child {
        .location-category {
          position: relative;
          &::after {
            content: "";
            height: 1px;
            width: 14px;
            background: #d2d3d5;
            position: absolute;
            left: -20px;
          }
        }
      }
      .location-item.child {
        .location-row {
          margin-right: 52px;
        }
      }
      .location-item.child.parent {
        .location-row {
          margin-right: 28px;
        }
      }
      .location-item.child.children {
        .location-row {
          margin-right: 62px;
        }
      }
    }
    .selects-list-wrapper {
      max-width: 368px;
      width: 100%;
      position: absolute;
      top: 75px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      padding-top: 16px;
      z-index: 999;
      .select-lists {
        max-height: 420px;
        overflow-y: auto;
        margin: 0 16px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #abaaba;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #807f97;
        }
        padding-right: 4px;
        .select-item-box {
          position: relative;
          overflow: hidden;
          .select-item {
            width: 100%;
            border-radius: 4px;
            border: solid 1px #dfdfea;
            padding: 12px;
            font-size: 14px;
            color: #000000;
            margin-top: 6px;
            font-size: 14px;
            cursor: pointer;
          }
          .select-item.child {
            position: relative;
            width: 90%;
            margin-left: auto;
          }
          .select-item.child.children {
            width: 85%;
          }
          .down-box {
            position: absolute;
            margin-left: auto;
            width: 40px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0px;
            top: 10px;
            cursor: pointer;
            &:after {
              content: "";
              width: 1px;
              background: #e0e0e4;
              height: 26px;
              position: absolute;
              left: 0px;
              top: 8px;
            }
          }
        }
      }
      .selected-item {
        border: solid 2px var(--color-theme-primary) !important;
        color: var(--color-theme-primary) !important;
        font-family: "Gilroy-ExtraBold";
      }
      .selected-some-brand {
        border: solid 2px var(--color-theme-primary) !important;
        border-style: dashed !important;
        color: var(--color-theme-primary) !important;
      }
      .count-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 14px 16px 8px 16px;
        div {
          color: #9696a0;
          font-size: 12px;
          font-family: "Gilroy-Bold";
        }
      }
      .controls-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px 16px 16px;
        border-top: 1px solid #e0e0e4;
        margin-top: 5px;
        & > :first-child {
          color: var(--color-theme-primary);
          font-family: "Gilroy-ExtraBold";
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.search-location {
  position: relative;
  margin: 0 16px;
  input {
    height: 44px;
    min-width: 280px;
    background-color: #eef2f7;
    padding-left: 40px;
    padding-right: 12px;
    width: 100%;
    border: none;
    outline: none;
    color: #000000;
    font-size: 14px;
    border-radius: 2px;
  }
  img {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 24px;
    width: 24px;
  }
}

.retailers-order-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 25px;
  .retailer-order-item {
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    padding: 0 6px 2px 6px;
    text-align: center;
    font-size: 12px;
    color: #9696a0;
    font-family: Gilroy-Bold;
    margin-right: 5px;
    img {
      margin-top: 6px;
      width: 25px;
    }
  }
  & > :last-child {
    margin-right: 0px;
  }
}

.path-purchase-header {
  display: flex;
  justify-content: space-between;
}

.average-info-wrapper {
  max-width: 388px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .average-info-item {
    max-width: 186px;
    width: 100%;
    background: #f7f8fb;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-family: "Gilroy-ExtraBold";
    font-size: 14px;
    & > :last-child {
      font-size: 22px;
      margin-top: 12px;
    }
  }
}

.search-product-box {
  display: flex;
  align-items: center;
  .search input {
    width: 280px;
  }
  .search-location {
    margin-left: 0;
  }
  .get-prod-btn {
    border-radius: 4px;
    background-color: var(--color-theme-primary);
    padding: 11px 30px;
    color: #ffffff;
    font-size: 14px;
    font-family: Gilroy-ExtraBold;
    max-width: 280px;
    width: 100%;
    text-align: center;
    margin-left: 16px;
    cursor: pointer;
  }
}

.location-tooltip {
  background: #fff;
  text-align-last: left;
  .title {
    color: #000000;
    font-size: 12px;
    font-family: "Gilroy-ExtraBold";
  }
  .value {
    font-family: "Gilroy-Medium";
    font-size: 12px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > :last-child {
      margin-left: 30px;
    }
  }
  .link {
    font-family: "Gilroy-ExtraBold";
    color: var(--color-theme-primary);
    font-size: 12px;
    margin-top: 15px;
    cursor: pointer;
  }
}

.tooltip-piechart {
  font-family: "Gilroy-Medium";
  color: #000000 !important;
  font-size: 14px !important;
}

.wrapper-sharw-popup {
  .wrapper-share-chart {
    position: relative;
    padding-top: 45px;
    .current-retailer.brands {
      position: absolute;
      margin: 0;
      right: 0;
      top: 0px;
      justify-content: space-between;
      z-index: 9;
    }
  }
}

.wrapper-share-table {
  .header-share-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 25px;
    .search-share-table {
      max-width: 320px;
      width: 100%;
      display: flex;
      .search-location {
        margin: 0;
      }
      img {
        cursor: pointer;
      }
    }
    .title-share-table {
      max-width: 220px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: "Gilroy-Bold";
      color: #9696a0;
      cursor: pointer;
    }
    .value-share-table {
      max-width: 180px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: "Gilroy-Bold";
      color: #9696a0;
      cursor: pointer;
    }
  }
  .wrapper-body-share-table {
    position: relative;
    .body-share-table {
      max-height: 420px;
      overflow-y: auto;
      @media (max-height: 950px) {
        max-height: 240px;
      }
      @media (max-height: 680px) {
        max-height: 150px;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
      .item-share-table {
        display: flex;
        align-items: stretch;
        border-bottom: 1px solid #e0e0e4;
        justify-content: space-between;
        .item-share-title {
          display: flex;
          align-items: center;
          max-width: 320px;
          width: 100%;
          padding: 20px 6px;
          font-family: "Gilroy-Medium";
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
        }
        .item-share-param {
          max-width: 220px;
          width: 100%;
          padding: 20px 6px;
          padding-left: 8px;
          font-family: "Gilroy-Medium";
          display: flex;
          align-items: center;
          .color-box {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            display: block;
            margin-right: 10px;
          }
        }
        .item-share-param.border {
          border-left: 1px solid #e0e0e4;
          border-right: 1px solid #e0e0e4;
          padding-left: 18px;
        }
        .item-share-position {
          max-width: 180px;
          width: 100%;
          padding: 20px 6px;
          padding-left: 8px;
          font-family: "Gilroy-Medium";
          display: flex;
          align-items: center;
        }
        .item-share-position.border {
          border-left: 1px solid #e0e0e4;
          border-right: 1px solid #e0e0e4;
          padding-left: 18px;
        }
      }
    }
  }
}

.ant-tooltip-inner {
  z-index: 1;
}

.purchase-product-wrapper {
  margin-top: 35px;
  .purchase-product-header {
    display: flex;
    align-items: center;
    background: #f7f8fb;
    padding: 16px;
    position: relative;
    .purchase-product-image {
      width: 85px;
      height: 85px;
      margin-right: 15px;
    }
    .purchase-product-box {
      width: 100%;
    }
    .purchase-product-title {
      font-family: "Gilroy-ExtraBold";
      color: #000000;
      font-size: 14px;
    }
    .purchase-product-info {
      display: flex;
      margin-top: 15px;
      .purchase-product-average {
        max-width: 160px;
        width: 100%;
        & > :first-child {
          font-family: "Gilroy-ExtraBold";
          color: #000000;
          font-size: 14px;
        }
        & > :last-child {
          font-family: "Gilroy-Medium";
          color: #000000;
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }
    .purchase-product-cancel {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  }

  .ant-collapse-content {
    border: none;
  }
  .ant-collapse {
    border: none;
    background: inherit;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 20px 16px !important;
    border-bottom: 2px solid #e0e0e4;
    padding-left: 48px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
  }
  .headaer-collapse-purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Gilroy-ExtraBold";
    font-size: 14px;
    color: #000000;
    .headaer-collapse-title {
      max-width: 577px;
      width: 100%;
    }
    .headaer-collapse-retailer {
      max-width: 85px;
      width: 100%;
    }
    .headaer-collapse-value {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      div {
        height: 18px;
      }
    }
    .headaer-collapse-value.amount {
      max-width: 70px;
      width: 100%;
    }
    .headaer-collapse-value.clicks {
      max-width: 128px;
      width: 100%;
    }
  }
  .table-product-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid #e0e0e4;
    .table-product-title {
      max-width: 600px;
      width: 100%;
    }
    .table-product-retailer {
      max-width: 68px;
      width: 100%;
    }
    .table-product-rank {
      max-width: 52px;
      width: 100%;
    }
    .table-product-clicks {
      max-width: 128px;
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: "Gilroy-Bold";
      font-size: 12px;
      color: #9696a0;
    }
  }
  .table-product-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e0e0e4;
    .product-item-title {
      max-width: 600px;
      width: 100%;
    }
    .product-item-rank {
      max-width: 52px;
      width: 100%;
    }
    .product-item-clicksToPurchase {
      max-width: 128px;
      width: 100%;
    }
    .product-item-retailer {
      max-width: 68px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 25px;
      }
    }
    div {
      font-family: "Gilroy-Medium";
      color: #000000;
      font-size: 14px;
      padding: 20px 0;
    }
  }
}

.share-dropdown-wrapper {
  .share-dropdown {
    background: #eef2f7;
    color: #000;
    padding: 11px 15px;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
      margin-right: 10px;
    }
    .ant-dropdown {
      max-width: 235px;
    }
  }
  .ant-dropdown.ant-dropdown-placement-bottomLeft {
    max-width: 235px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
